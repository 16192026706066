import React from 'react';
import './PageNotFound.css'


const PageNotFound = () => {
    return (
        <div className="page-container">
            <h1>Error: Page Not Found</h1>
        </div>

    );
};

export default PageNotFound;
